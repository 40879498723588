import React from "react"

import Layout from "../layouts/index"
import {
  StandardContainer,
  TextBlock,
  StyledButton,
} from "../components/common"

const StyleGuide = () => {
  return (
    <Layout>
      <StandardContainer>
        <h1>Heading Level 1</h1>
        <h2>Heading Level 2</h2>
        <h3>Heading Level 3</h3>
        <h4>Heading Level 4</h4>
        <br />
        <TextBlock>
          <h2>Text Block</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
            semper facilisis posuere. Sed fermentum consectetur dolor, quis
            porta risus cursus vel. Cras nisl lacus, rhoncus id velit ac,
            tincidunt tincidunt mauris. Aenean at lacinia ex, ut gravida augue.
            Ut eget consectetur massa, sit amet finibus turpis. Donec finibus
            massa ut iaculis consectetur.
          </p>
        </TextBlock>
        <br />
        <TextBlock measure-m>
          <h5>How it works</h5>
          <h2>Text Block</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
            semper facilisis posuere. Sed fermentum consectetur dolor, quis
            porta risus cursus vel. Cras nisl lacus, rhoncus id velit ac,
            tincidunt tincidunt mauris. Aenean at lacinia ex, ut gravida augue.
            Ut eget consectetur massa, sit amet finibus turpis. Donec finibus
            massa ut iaculis consectetur.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
            semper facilisis posuere. Sed fermentum consectetur dolor, quis
            porta risus cursus vel. Cras nisl lacus, rhoncus id velit ac,
            tincidunt tincidunt mauris. Aenean at lacinia ex, ut gravida augue.
            Ut eget consectetur massa, sit amet finibus turpis. Donec finibus
            massa ut iaculis consectetur.
          </p>
          <StyledButton>View Features</StyledButton>
          <StyledButton outlined>View Features</StyledButton>
        </TextBlock>
      </StandardContainer>
    </Layout>
  )
}

export default StyleGuide
